import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TournamentLookup from './components/TournamentLookup';
import PlayerLookup from './components/PlayerLookup';
import { FirebaseProvider } from './context/FirebaseContext';
import './App.css';

const App = () => {
  
  return (
    <FirebaseProvider>
      <Router>
        <Routes>
          <Route path="/" element={<TournamentLookup />} />
          <Route path="/:tournamentId" element={<PlayerLookup />} />
        </Routes>
      </Router>
    </FirebaseProvider>
  );
};

export default App;