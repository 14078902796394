import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useFirebase } from '../context/FirebaseContext';

const MatchHistory = ({ matches, playerCossyId }) => {
  const { db } = useFirebase();
  const [opponentNames, setOpponentNames] = useState({});

  // Function to fetch opponent name based on cossyId
  const fetchOpponentName = async (cossyId) => {
    if (!opponentNames[cossyId]) {  // Only fetch if not already in the state
      try {
        const opponentRef = doc(db, 'tournaments/test/participants', cossyId);  // Adjust "test" with the actual tournamentId dynamically if needed
        const opponentDoc = await getDoc(opponentRef);
        
        if (opponentDoc.exists()) {
          const { firstName, lastName } = opponentDoc.data();
          setOpponentNames((prevNames) => ({
            ...prevNames,
            [cossyId]: `${firstName} ${lastName}`
          }));
        } else {
          setOpponentNames((prevNames) => ({
            ...prevNames,
            [cossyId]: 'Unknown Opponent'
          }));
        }
      } catch (error) {
        console.error('Error fetching opponent name:', error);
        setOpponentNames((prevNames) => ({
          ...prevNames,
          [cossyId]: 'Error Loading Name'
        }));
      }
    }
  };

  // Process matches snapshot into array of match data
  const getMatchesData = () => {
    const matchDocs = [];
    matches.forEach((doc) => {
      matchDocs.push({
        id: doc.id,
        ...doc.data()
      });
    });

    // Filter and format matches where player participated
    return matchDocs
      .filter(match => 
        match.player1 === playerCossyId || match.player2 === playerCossyId
      )
      .map(match => {
        let result;
        if (match.status === "Draw") {
          result = "Draw";
        } else if (match.status === "Incomplete") {
          result = "To be determined";
        } else {
          result = match.winner === playerCossyId ? "Won" : "Lost";
        }

        const opponentId = match.player1 === playerCossyId 
          ? match.player2 
          : match.player1;

        fetchOpponentName(opponentId);  // Fetch opponent name

        const roundNum = match.round || parseInt(match.id.match(/R(\d+)/)?.[1]) || 0;

        return {
          round: roundNum,
          table: match.table,
          opponentId: opponentId,
          result: result
        };
      })
      .sort((a, b) => a.round - b.round);
  };

  const matchesData = getMatchesData();

  return (
    <div className="round-section">
      <h2>Match History</h2>
      <div id="matchups">
        {matchesData.length === 0 ? (
          <p>No matches found for this player.</p>
        ) : (
          matchesData.map((match, index) => (
            <div key={index} className="matchup">
              <h3>Round {match.round}</h3>
              <p><strong>Opponent:</strong> {opponentNames[match.opponentId] || 'Loading...'} ({match.opponentId})</p>
              <p><strong>Table:</strong> {match.table}</p>
              <p><strong>Result:</strong> {match.result}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MatchHistory;
