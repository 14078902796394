import React from 'react';

const PlayerInfo = ({ playerData }) => {
  return (
    <div className="player-info">
      <h2>Player Info</h2>
      <div className="record">
        <div className="record-item wins">Wins: {playerData.wins}</div>
        <div className="record-item draws">Draws: {playerData.draws}</div>
        <div className="record-item losses">Losses: {playerData.losses}</div>
      </div>
      <p className="tiebreaker">
        <strong>Tiebreaker Points:</strong> {playerData.tieBreaker}
      </p>
      <p><strong>Rank:</strong> {playerData.rank}</p>
      <p><strong>Score:</strong> {playerData.score}</p>
    </div>
  );
};

export default PlayerInfo;